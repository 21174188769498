/* Intro with text CSS */
.features3__item {
    position: relative;
    z-index: 0;
}
.loading-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 0;
}
.loading-add .icon {
    position: relative;
    z-index: 10;
}
.features3__item .intro-text {
    text-align: center;
    position: absolute;
    z-index: 10;
    display: none;
}
.features3__item .intro-text h4 {
    color: #FFF!important;
}
.features3__item .intro-text p {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #FFF;
}
.overlay.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* semi-transparent black */
    z-index: 99999; /* ensure it's on top of other elements */
    display: block; /* hide by default */
}
#intro-first .intro-text {
    bottom: 190px;
} 
#intro-second .intro-text {
    bottom: 190px;
} 
#intro-third .intro-text {
    bottom: 190px;
} 
#intro-forth .intro-text {
    bottom: 190px;
} 



/* Loading the intro css */
.loading-add.active {
    z-index: 5;
}
.loading-add.active::before {
    content: "";
    width: 500px;
    height: 500px;
    background: #000;
    color: #FFF;
    z-index: 1;
    position: absolute;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.features3__item.active .title {
    display: none;
}
.features3__item.active .intro-text {
    display: block;
}

#intro-first .loading-add.active::before {
    bottom: -40px;
}
#intro-second .loading-add.active::before {
    bottom: -40px;
}
#intro-third .loading-add.active::before {
    bottom: -40px;
}
#intro-forth .loading-add.active::before {
    bottom: -40px;
}

/* .zindex0 {
    z-index: 0 !important;
}
.zindex1 {
    z-index: 1 !important;
} */

@media only screen and (max-width: 575px) {
    #intro-first .intro-text {
        bottom: 180px;
        width: 200px;
        text-align: left;
        left: 10px;
    }
}


.modal-buttons {
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative;
    gap: 10px;
}
.logo_comment_watch {
    position: absolute;
    display: flex;
    top: 0px;
    right: 0px;
    align-items: center;
    gap: 0px;
    filter: var(--filter);
}

.skip-btn {
    width: 62px;
    padding: 2px 15px;
    /* position: absolute; */
    top: 5px;
    right: 0;
    background: rgb(222, 222, 223);
    border-radius: 20px;
    color: #0E1013;
    z-index: 9999;
}
.comment-ad-section {
    height: 200px;
    width: 100%;
    overflow-y: auto;
}