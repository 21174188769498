/* Intro with text CSS */
.loading-add2 {
  position: relative;
  z-index: 0;
}
.loading-add2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 0;
}
.loading-add2.active .reps-count {
  position: relative;
  z-index: 10;
  width: 42px;
  height: 42px;
  background: #fff;
  color: #000;
  min-width: 42px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid rgba(14 16 19 / 20%);
}
.loading-add2 .intro-text {
  text-align: center;
  position: absolute;
  z-index: 10;
  display: none;
}
.loading-add2 .intro-text h4 {
  color: #fff!important;
}
.loading-add2 .intro-text p {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #fff;
}
.overlay.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); 
  z-index: 99999; 
  display: block; 
}

/* Loading the intro css */
.loading-add2.active {
  z-index: 5;
}
.loading-add2.active::before {
  content: "";
  width: 500px;
  height: 500px;
  background: #000;
  color: #fff;
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.loading-add2.active .title {
  display: none;
}
.loading-add2.active .intro-text {
  display: block;
}
.loading-add2.active .share-btn {
  position: relative;
  z-index: 10;
}

#intro-five .intro-text {
  bottom: -60px;
  width: 240px;
  text-align: left;
  left: -225px;
}

#intro-five.loading-add2.active::before {
  bottom: -150px;
  left: -330px;
}

#intro-six .intro-text {
  bottom: -60px;
  width: 240px;
  text-align: left;
  left: -225px;
}

#intro-six.loading-add2.active::before {
  bottom: -150px;
  left: -330px;
}

@media only screen and (max-width: 767px) {
  #intro-six.loading-add2.active::before {
    left: -265px;
  }
  #intro-six .intro-text {
    bottom: -95px;
    width: 240px;
    text-align: left;
    left: -120px;
  }
  .loading-add2{
    display: none;
  }
}
@media (max-width: 576px) {
  .mobile_text {
    background-color: var(--whitebg);
    z-index: 9999 !important;
  }
  .mobile_responsive{
    margin-left: 10px!important;
  }
}

.btn-theme {
  background: var(--heading-color);
  color: var(--bg-color);
  border-radius: 10px;
  border: 1px solid var(--border-color);
}