.transaction-history-container1 {
    padding: 1rem;
    max-width: 800px;
    margin: auto;
    margin-top: 0;
  }
  .transaction-history-container {
    padding: 1rem;
    max-width: 800px;

    color: white;
    margin: auto;
    margin-top: 0;
  }
  

  .transaction-title {
    text-align: center;
    margin-bottom: 1rem;
    color: #fff;
  }

  
  .filter-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .date-input {
    width: 300px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    border: "1px solid #ddd",
    
  }
  
  .date-input1 {
    width: 300px;
    padding: 0.5rem;
    border: 1px solid white;
    border-radius: 5px;
    background-color:black;
    color: white;
  }

  .apply-btn {
    background-color: #28a745;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .apply-btn1 {
    background-color: black;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
  }
  .apply-btn1:hover {
    background-color: green; 
  }
  
  .paymetid1{
 
    color: white;
    font-size: 16px;
  }
  .paymentid{
   
    color:black;
    font-size: 16px;
  }
  .transactions-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
   color: black;
  }

  .transactions-list1 {
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    color: white;
    gap: 1rem;
  }
  
  .transaction-card {
    background-color: #1e1e1e;
    padding: 1rem;
    border-radius: 8px;
    color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
   
  .transaction-card-light {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    color: black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  

  
  .status {
    font-size: 0.9rem;
    font-weight: bold;
  }

  
  .income .amount, .income .status {
    color: #28a745;
  }
  
  .refund .amount, .refund .status {
    color: #dc3545;
  }
  

  @media (max-width: 768px) {
    .filter-section {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .date-input,
    .apply-btn {
      width: 100%;
    }
  }
  