.typer::after {
    content: '' !important;
}
.typer {
    margin-left: 20px;
}
.brhide {
    display: none;
}

@media only screen and (max-width: 567px) {
    .brhide {
        display: block;
    }
}