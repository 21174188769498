.custom-datepicker-wrapper {
  width: 100% !important;
}
.package_desc {
  position: relative;
  border-radius: 0 0 10px 10px;
  padding: 10px;
}
@media only screen and (max-width: 767px) {
  .detail_page_auto {
    height: auto;
    /* max-height: 100px; */
  }
}
@media only screen and (max-width: 767px) {
  .images_main img {
    width: 95%;
    height: auto;
    /* max-height: 100px; */
    margin: 0 auto;
    display: flex;
    border-radius: 10px 10px 0 0;
  }
}
.logo_beta span {
  font-size: 16px;
  line-height: 16px;
  margin-left: 5px;
  color: var(--font-color) !important;
}
a.logo_beta {
  display: flex;
  align-items: end;
  height: 100%;
}
.package_desc h4 {
  font-size: 21px;
  color: #fff !important;
  width: 70%;
  font-size: 15px;
}

.package_desc p {
  font-size: 12px;
  color: #fff;
  width: 70%;
}
.offer_div {
  position: absolute;
  right: 15px;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 95px;
  text-align: center;
  top: 0;
  border-radius: 0 0 10px 10px;
  padding: 5px;
  max-height: 75px;
}
.lable-date-div .notification-date {
  font-size: 18px;
  color:  var(--font-color) !important;
  font-weight: 500;
}





.modal-buttons_1 {
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
  gap: 10px;
  position: absolute;
  top: 0;
  z-index: 999;
  background: rgb(255 255 255 / 23%);
  width: 100%;
  padding: 10px 40px;
}


.add_skip {
  width: 62px;
  padding: 2px 15px;
  /* position: absolute; */
  /* top: 5px; */
  /* right: 0; */
  background: rgb(222, 222, 223);
  border-radius: 20px;
  color: #0E1013;
  z-index: 9999;
}
