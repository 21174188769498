
.add_stick.stickthis {
    z-index: 999!important;
    background: var(--bg-modal);
    padding: 15px 0;
    /* width:100%!important; */
}
.search_top input {
    border: 1px solid var(--border-color) !important;
    background: var(--bg-color);
    height: 50px;
    width: 100%;
    border-radius: 8px;
    padding-left: 45px;
    color: var(--color);
}