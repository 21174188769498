.bottom_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  .comment-section {
    height: 400px; /* Set your desired height */
    width: 100%; /* Set your desired width */
    overflow-y: auto; /* Enable vertical scrolling */
  }