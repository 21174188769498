/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
    background-color: #ccc;
}

.accordion span {
    float: right;
    font-weight: bold;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
    padding: 0 18px;
    background-color: white;
    overflow: hidden;
}
.mt_100 {
    margin-top: 100px;
}
.impression_main {
    background: #fff !important;
    display: flex;
    border-radius: 8px;
    padding: 15px;
}

.impression_main h1, h2, h3, h4, h5, h6 {
    color: unset;
}