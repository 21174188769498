body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* for serach box  */

input.serch-input {
  border-radius: 20px ;
  margin-right: 10px;
  padding: 7px 10px  ;
  padding-left: 18%;
  width: 190px;
  height: 4vh;
  background-color: #0e1013;
  color: #FB9515;
  border:  1px solid #FB9515
}
/* serch-input1 */
input.serch-input1 {
  border-radius: 20px ;
  margin-right: 10px;
  padding: 7px 10px  ;
  padding-left: 18%;
  width: 190px;
  height: 4.5vh;
  background-color: #fff;
  color: #FB9515;
  border:  1px solid #FB9515
}
input.serch-input::placeholder {
  color: #FB9515 ;
  padding-left: 2%; 
  opacity: 0.8; 

}


.search-icon {
  position: absolute;
  top: 50%;
  right: 1px; 
  transform: translateY(-50%);
  color: blue; 
}

.image_icon_search {
  position: absolute;
  top: 8px;
  left: 10px;
}


@media (max-width: 768px) {
  input.serch-input,
  input.serch-input1 {
    height: 4vh;
    width: 190px;
    padding-left: 18%;
  }

  .tags_selection,
  .tags_selection1 {
    padding-left: 0; 
  }

  .tags_selection li,
  .tags_selection1 li {
    margin-bottom: 10px;
  }

  .image_icon_search {
    bottom: 10px;
  }
}


@media (max-width: 576px) {
  input.serch-input,
  input.serch-input1 {
    height: 4.5vh;
    width: 190px;
    font-size: 18px;
  }

  .tags_selection li,
  .tags_selection1 li {
    margin-bottom: 8px;
  }

  .image_icon_search {
    top: 10px;
  }
}

