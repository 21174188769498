.ajax-preloader {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
    display: none;
}

.visible {
    display: block;
}